import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "disabling", "enablingCheckbox" ]

  connect() {
    if (this.enablingCheckboxTarget.checked) {
      this.#enable()
    } else {
      this.#disable()
    }
  }

  toggleCheckbox({ target: { checked } }) {
    if (checked) {
      this.#enable()
    } else {
      this.#disable()
    }
  }

  // Private
  #enable() {
    this.disablingTargets.forEach((disablingTarget) => disablingTarget.disabled = false)
  }

  #disable() {
    this.disablingTargets.forEach((disablingTarget) => disablingTarget.disabled = true)
  }
}
